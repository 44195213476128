export default {
  name: "ClaimsUpdateStatusLayout",
  data() {
    return {
      texts: "",
      statusSelected: "",
      statusDescription: "",
      sAdminOwnerId: "",
      sManager: "",
      aManagers: [],
      aStatus: [],
      screenWidth: 0,
      bLoading: false,
      dataImgs: [],
    };
  },
  beforeMount() {
    this.texts = FILE.claimsTexts[this.selectLanguage];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    this.closeButton();
  },
  methods: {
    getManagers() {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/administrators`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {},
      })
        .then((response) => {
          this.aManagers = response.data.results.map((e) => {
            return {
              ...e,
              sFullName: e.sName + " " + e.sLastname,
            };
          });
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    sendUpdateStatus1: function() {
      let form = new FormData();
      let data = {};
      let arr = [];

      this.dataImgs.forEach((element) => {
        form.append("file", element);
      });
      // data = { file: arr };
    },
    sendUpdateStatus: function() {
      this.bLoading = true;

      const payload = {
        sClaimTicketStatusId: this.statusSelected,
        sResolutionMessage: this.statusDescription,
        sAdminOwnerId: this.$store.state.sAdministratorId,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/status/claim-tickets/${this.dialogUpdateStatusArrClaims.sClaimTicketId}`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;
          this.closeButton();
          this.mixSuccess(response.data.message);
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    uploadImgs: function(message, orderId, recordId) {
      let form = new FormData();

      this.dataImgs.forEach((element) => {
        form.append("image", element);
      });

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.patch(
        `${URI}/api/v1/${this.selectLanguage}/purchase-orders/${orderId}/records/${recordId}/images`,
        form,
        config
      )
        .then((response) => {
          this.bLoading = false;

          this.closeButton();
          this.mixSuccess(message);
          // this.$router.push("admin/orders");
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.bLoading = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
    removeItem: function(item, index) {
      this.dataImgs.splice(index, 1);
    },
    maskedFile: function(file) {
      return URL.createObjectURL(file);
    },
    uploadFilesWarehouse: function(e) {
      const files = e.target.files;

      if (files.length > 0) {
        if (files.length < 16) {
          for (let i = 0; i < files.length; i++) {
            const element = files[i];
            this.dataImgs.push(element);
          }
        } else {
          this.mixError(this.texts.orders.updateState.textResponseLimitImages);
        }
      }
    },
    getStatus: function() {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/status/claim-tickets/${this.dialogUpdateStatusArrClaims.oStatus.sStatusId}/adjacency`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        }
      )
        .then((response) => {
          this.aStatus = response.data.results.aAdjacency;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    handleResize: function() {
      if (window.innerWidth >= 960) {
        this.screenWidth = 40;
      } else {
        this.screenWidth = 100;
      }
    },
    closeButton: function() {
      this.$store.commit("setDialogUpdateStatusClaims", {
        active: false,
        arr: [],
      });
      this.dataImgs = [];
      this.statusSelected = "";
      this.statusDescription = "";
    },
    emitClose: function() {
      this.$store.commit("setDialogUpdateStatusClaims", {
        active: false,
        arr: [],
      });
      this.dataImgs = [];
      this.statusSelected = "";
      this.statusDescription = "";
    },
  },
  computed: {
    dialogUpdateStatusClaims: function() {
      return this.$store.state.dialogUpdateStatusClaims;
    },
    dialogUpdateStatusArrClaims: function() {
      return this.$store.state.dialogUpdateStatusArrClaims;
    },
    selectLanguage: function() {
      return this.$store.state.language;
    },
    validateForm: function() {
      return (
        this.statusSelected !== "" &&
        this.statusSelected !== null &&
        this.statusDescription !== ""
      );
    },
  },
  watch: {
    dialogUpdateStatusClaims: function() {
      if (this.dialogUpdateStatusClaims) {
        this.texts = FILE.claimsTexts[this.selectLanguage];
        this.getStatus();
        this.getManagers();
      }
    },
  },
};
